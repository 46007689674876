import commonAction from 'actions/commonAction';
import PaymentsApi from 'api/generated/PaymentsApi';

export const SETUP_IN_PAYMENTS_ACTION = 'SETUP_IN_PAYMENTS';

export const setupInPayments = (userId: string) =>
    commonAction(async () => new PaymentsApi().setupInPayments(userId), SETUP_IN_PAYMENTS_ACTION, {
        toastErrorMessage: 'Unable to setup user',
        toastInitialMessage: 'Setting up user in payments',
    });
