import commonAction from 'actions/commonAction';
import { COPY_HOUSEHOLD_INFO_524_TOAST_MESSAGE } from 'actions/user/copyHouseholdInfo';
import TeamsApi from 'api/generated/TeamsApi';

export const TRANSITION_TO_RENEWING_ACTION = 'TRANSITION_TO_RENEWING';

export const transitionToRenewing = (teamId: string) =>
    commonAction(
        async () => new TeamsApi().transitionTeamToRenewing(teamId),
        TRANSITION_TO_RENEWING_ACTION,
        {
            toastErrorMessage(response) {
                if (response.error.message === 'Network Error') {
                    return COPY_HOUSEHOLD_INFO_524_TOAST_MESSAGE;
                } else {
                    return 'Failed to transition team to renewing';
                }
            },
            toastSuccessMessage: 'Successfully transitioned team to renewing',
        }
    );
