import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION =
    'GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS';

export const getPlansForNextYear = (pathwayBlueprintId: string | undefined) =>
    commonAction(
        async () => new PathwayBlueprintApi().getPlansForNextYear(pathwayBlueprintId),
        GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION,
        {
            toastErrorMessage: 'Unable to get plans for next year.',
        }
    );
