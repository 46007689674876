import commonAction from 'actions/commonAction';
import {
    HouseholdMemberTypes,
    OtherCoverageEligibilities,
    ProviderSource,
    SurveyTypes,
    TaxFilingStatuses,
} from 'api/generated/enums';
import {
    Address,
    CustomQuestionAnswer,
    Drug,
    HouseholdMemberDto,
    IAddress,
    ICustomQuestionAnswer,
    IHouseholdMemberDto,
    IMemberVerifiedInfo,
    INote,
    IProvider,
    IQualitativeAnswers,
    IQualitativeHealthInsuranceAnswers,
    IQualitativeOtherBenefitsAnswers,
    IUser,
    IUserProfile,
    IYearlyUserInfoDto,
    MemberVerifiedInfo,
    Note,
    Provider,
    QualitativeAnswers,
    QualitativeHealthInsuranceAnswers,
    QualitativeOtherBenefitsAnswers,
    Survey,
    User,
    UserProfile,
    YearlyUserInfoDto,
} from 'api/generated/models';
import SurveyApi from 'api/generated/SurveyApi';
import { AxiosResponse } from 'axios';
import { getIdeonProviderName } from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import { push } from 'connected-react-router';
import { startCase } from 'lodash';
import { ISurveyState } from 'pages/survey/surveyState';
import { hasValue, stringToFloat } from 'utilities';

const getVerifiedInfoFromSurveyData = (
    member: ISurveyState['member'],
    income: ISurveyState['income'],
    includeFaithBasedQuestionInSurvey: boolean | undefined,
    coverage: ISurveyState['coverage'],
    user: IUser | undefined,
    hasSpouseOtherCoverage: boolean | undefined,
    isIchra: boolean
) => {
    const mvi: IMemberVerifiedInfo = ({
        additionalIncome: !isIchra ? stringToFloat(income.additionalIncome) : null,
        dateOfBirth: member.dateOfBirth,
        deductions: !isIchra ? (stringToFloat(income.deductions) as number) : null,
        drugs: coverage.selectedDrugs.map((d) => new Drug(d)),
        employeeIncome: stringToFloat(income.employeeIncome) as number,
        filingStatusId: !isIchra
            ? TaxFilingStatuses[income.filingStatus as keyof typeof TaxFilingStatuses]
            : TaxFilingStatuses.Unknown,
        gender: member.gender,
        hasHouseholdPreExistingConditions: JSON.parse(coverage.hasHouseholdPreExistingConditions),
        hasInterestInFaithBasedSharingOptions:
            !isIchra && includeFaithBasedQuestionInSurvey
                ? JSON.parse(coverage.hasFaithBasedSharingInterest)
                : null,
        isPregnant: !isIchra ? member.isPregnant ?? false : null,
        occupation: '',
        otherCoverageEligibilities: OtherCoverageEligibilities.None,
        preferredProviders: coverage.selectedProviders.map((p) => {
            const isNotUserSource = hasValue(p.source) && p.source !== ProviderSource.User;
            return new Provider(({
                ...p,
                name: isNotUserSource ? getIdeonProviderName(p) : startCase(p.name),
                phoneNumber: p.phone,
                taxonomy: p.specialty,
            } as unknown) as IProvider);
        }),
        surveyTypeCompleted: isIchra ? SurveyTypes.ICHRA : user?.surveyTypeToSend,
        userId: user?.userId,
        usesTobacco: member.usesTobacco as boolean,
    } as unknown) as IMemberVerifiedInfo;
    if (coverage.hasParentOtherCoverage && !isIchra) {
        mvi.otherCoverageEligibilities |= OtherCoverageEligibilities.Parent;
    }
    if (hasSpouseOtherCoverage && !isIchra) {
        mvi.otherCoverageEligibilities |= OtherCoverageEligibilities.SpouseEmployer;
    }
    return new MemberVerifiedInfo(mvi);
};

export const SUBMIT_SURVEY_ACTION = 'SUBMIT_SURVEY';

export const submitSurvey = (
    user: IUser | undefined,
    {
        contact,
        coverage,
        income,
        member,
        household: { members },
        qualitative,
        includeFaithBasedQuestionInSurvey,
    }: ISurveyState,
    token: string,
    redirectUrl: string,
    isIchra: boolean
) => {
    const hasSpouseOtherCoverage = members?.some(
        (h) =>
            h.householdMemberTypeId === HouseholdMemberTypes.Spouse &&
            h.hasEmployerOtherCoverage &&
            !h.hasSameEmployer
    );
    const preExisting = {
        noteText: `preExistingConditions:${coverage.householdPreExistingConditionsNote}`,
    };
    const notes = [preExisting];
    const survey = new Survey({
        token,
        customQuestionAnswers: Object.keys(qualitative.customQuestionAnswers).map(
            (k) =>
                new CustomQuestionAnswer({
                    answer: qualitative.customQuestionAnswers[k],
                    customQuestionId: k,
                } as ICustomQuestionAnswer)
        ),
        householdMembers: members?.map((x) => {
            const hhm: Partial<IHouseholdMemberDto> = { ...x };
            hhm.userId = user?.userId as string;
            hhm.otherCoverageEligibilities = OtherCoverageEligibilities.None;
            if (!isIchra) {
                if (hasSpouseOtherCoverage) {
                    hhm.otherCoverageEligibilities |= OtherCoverageEligibilities.SpouseEmployer;
                }
                if (x.hasParentOtherCoverage) {
                    hhm.otherCoverageEligibilities |= OtherCoverageEligibilities.Parent;
                }
                if (
                    x.hasEmployerOtherCoverage &&
                    hhm.householdMemberTypeId !== HouseholdMemberTypes.Spouse
                ) {
                    hhm.otherCoverageEligibilities |= OtherCoverageEligibilities.DependentEmployer;
                }
            } else {
                hhm.otherCoverageSource = undefined;
            }

            if (hhm.householdMemberTypeId !== HouseholdMemberTypes.Spouse) {
                if (isIchra) {
                    hhm.householdMemberTypeId = HouseholdMemberTypes.IchraChild;
                } else if (hhm.householdMemberTypeId === HouseholdMemberTypes.IchraChild) {
                    hhm.householdMemberTypeId = HouseholdMemberTypes.Dependent;
                }
            }
            hhm.income = !isIchra ? Number(income[hhm.householdMemberId as string]) : undefined;
            return new HouseholdMemberDto(hhm as IHouseholdMemberDto);
        }),
        qualitativeAnswers: new QualitativeAnswers({
            anyFeedbackAnswer: qualitative.feedback.anyFeedback,
            healthInsuranceAnswers: hasValue(qualitative.rankCoverage)
                ? qualitative.rankCoverage
                      .filter((r) => r.isSelected)
                      .map(
                          (r, index) =>
                              new QualitativeHealthInsuranceAnswers({
                                  healthInsurancePartId: r.value,
                                  rank: index,
                              } as IQualitativeHealthInsuranceAnswers)
                      )
                : [],
            improveCurrentInsuranceAnswer: qualitative.feedback.improveHealthInsurance,
            likeAboutCurrentInsuranceAnswer: qualitative.feedback.likeCurrentInsurance,
            otherBenefitsAnswers: qualitative.otherBenefits
                .filter((o) => o.isSelected)
                .map(
                    (o) =>
                        new QualitativeOtherBenefitsAnswers({
                            otherBenefitId: o.value,
                        } as IQualitativeOtherBenefitsAnswers)
                ),
        } as IQualitativeAnswers),
        userProfile: new UserProfile({
            address: new Address((contact as unknown) as IAddress),
            memberVerifiedInfo: getVerifiedInfoFromSurveyData(
                member,
                income,
                includeFaithBasedQuestionInSurvey,
                coverage,
                user,
                hasSpouseOtherCoverage,
                isIchra
            ),
            user: new User({
                ...user,
                dateOfBirth: member.dateOfBirth,
                firstName: member.firstName,
                hireDate: member.hireDate,
                jobTitle: member.jobTitle,
                lastName: member.lastName,
                phone: contact.phone,
                preferredName: member.preferredName,
                surveyTypeToSend: isIchra ? SurveyTypes.ICHRA : SurveyTypes.Standard,
            } as IUser),
            userNotes: notes.map((x) => new Note(x as INote)),
            yearlyUserInfo: new YearlyUserInfoDto({
                needsMajorMedicalCoverage: member.needsCoverage,
                otherCoverageSource: isIchra ? null : member.otherCoverageSource,
            } as IYearlyUserInfoDto),
        } as IUserProfile),
    });
    return commonAction(
        async () => {
            let response: AxiosResponse<UserProfile>;
            if (token) {
                response = await new SurveyApi().submitSurveyWithToken(survey);
            } else {
                response = await new SurveyApi().submitSurvey(survey);
            }
            return response;
        },
        SUBMIT_SURVEY_ACTION,
        {
            additionalDispatchData: { isCurrent: true },
            successHook: ({ dispatch }) => dispatch(push(redirectUrl)),
            toastErrorMessage: 'Failed to submit survey',
        }
    );
};
