import { AppStoreThunkDispatch } from 'actions/commonAction';

export const clearApiActivity = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type: 'API_ACTIVITY_CLEAR',
    });
};

const clearApiError = (actionName: string) => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type: `${actionName}_CLEAR`,
    });
};

export const dispatchWrapper = (type: string) => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type,
    });
};

export const EDIT_USER = 'EDIT_USER';
export const ADD_USER = 'ADD_USER';
export const clearUserApiErrors = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch(clearApiError(EDIT_USER));
    dispatch(clearApiError(ADD_USER));
};

export const GET_COUNTIES = 'GET_COUNTIES';
export const clearGetCountiesError = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch(clearApiError(GET_COUNTIES));
};

export const ADD_TEAM = 'ADD_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const clearTeamApiErrors = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch(clearApiError(ADD_TEAM));
    dispatch(clearApiError(EDIT_TEAM));
};

export const CLEAR_HOUSEHOLD_MEMBERS = 'CLEAR_HOUSEHOLD_MEMBERS';
export const clearHouseholdMembers = () => dispatchWrapper(CLEAR_HOUSEHOLD_MEMBERS);

export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const clearUserProfile = () => dispatchWrapper(CLEAR_USER_PROFILE);

export const CLEAR_USER = 'CLEAR_USER';
export const clearUser = () => async (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        type: CLEAR_USER,
    });
    dispatch(clearUserApiErrors());
};

export const CLEAR_STATE = 'CLEAR_STATE';
export const clearState = () => dispatchWrapper(CLEAR_STATE);

export const CLEAR_USERS = 'CLEAR_USERS';
export const clearUsers = () => dispatchWrapper(CLEAR_USERS);

export const CLEAR_PAGED_TEAMS = 'CLEAR_PAGED_TEAMS';
export const clearPagedTeams = () => dispatchWrapper(CLEAR_PAGED_TEAMS);

export const CLEAR_TEAM = 'CLEAR_TEAM';
export const clearTeam = () => dispatchWrapper(CLEAR_TEAM);

export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const clearLogin = () => dispatchWrapper(CLEAR_LOGIN);

export const CLEAR_ROLE = 'CLEAR_ROLE';
export const clearRole = () => dispatchWrapper(CLEAR_ROLE);

export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';
export const clearPermissions = () => dispatchWrapper(CLEAR_PERMISSIONS);

export const CLEAR_TEAM_USER_ROLES = 'CLEAR_TEAM_USER_ROLES';
export const clearTeamUserRoles = () => dispatchWrapper(CLEAR_TEAM_USER_ROLES);

export const CLEAR_MARKETPLACE_PLANS = 'CLEAR_MARKETPLACE_PLANS';
export const clearMarketplacePlans = () => dispatchWrapper(CLEAR_MARKETPLACE_PLANS);

export const CLEAR_SHOPPING_PATHWAY_MARKETPLACE_PLAN = 'CLEAR_SHOPPING_PATHWAY_MARKETPLACE_PLAN';
export const clearShoppingPathwayMarketplacePlan = () =>
    dispatchWrapper(CLEAR_SHOPPING_PATHWAY_MARKETPLACE_PLAN);

export const CLEAR_SHOPPING_PATHWAY_MEDISHARE_PROGRAMS = 'CLEAR_SHOPPING_PATHWAY_MEDISHARE';
export const clearShoppingPathwayMedisharePrograms = () =>
    dispatchWrapper(CLEAR_SHOPPING_PATHWAY_MEDISHARE_PROGRAMS);

export const CLEAR_ALL_MEDISHARE_PLAN_DETAILS_AND_RATES =
    'CLEAR_ALL_MEDISHARE_PLAN_DETAILS_AND_RATES';
export const clearAllMediSharePlanDetailsAndRates = () =>
    dispatchWrapper(CLEAR_ALL_MEDISHARE_PLAN_DETAILS_AND_RATES);

export const CLEAR_USER_BUDGET = 'CLEAR_USER_BUDGET';
export const clearUserBudget = () => dispatchWrapper(CLEAR_USER_BUDGET);

export const CLEAR_SURVEY = 'CLEAR_SURVEY';
export const clearSurvey = () => dispatchWrapper(CLEAR_SURVEY);

export const CLEAR_COUNTIES = 'CLEAR_COUNTIES';
export const clearCounties = () => dispatchWrapper(CLEAR_COUNTIES);

export const CLEAR_MULTI_SELECT = 'CLEAR_MULTI_SELECT';
export const clearMultiSelect = () => dispatchWrapper(CLEAR_MULTI_SELECT);

export const CLEAR_TEAM_BENEFIT_TYPE_CARRIERS = 'CLEAR_TEAM_BENEFIT_TYPE_CARRIERS';
export const clearTeamBenefitTypeCarriers = () => dispatchWrapper(CLEAR_TEAM_BENEFIT_TYPE_CARRIERS);

export const CLEAR_GET_INFO_TO_SUBMIT_EXPENSE = 'CLEAR_GET_INFO_TO_SUBMIT_EXPENSE';
export const clearGetInfoToSubmitExpense = () => dispatchWrapper(CLEAR_GET_INFO_TO_SUBMIT_EXPENSE);

export const CLEAR_SUBMITTED_EXPENSE = 'CLEAR_SUBMITTED_EXPENSE';
export const clearSubmittedExpense = () => dispatchWrapper(CLEAR_SUBMITTED_EXPENSE);

export const CLEAR_REIMBURSEMENT_REPORT_YEARS = 'CLEAR_REIMBURSEMENT_REPORT_YEARS';
export const clearReimbursementReportYears = () =>
    dispatchWrapper(CLEAR_REIMBURSEMENT_REPORT_YEARS);

export const CLEAR_SUBMITTED_EXPENSES = 'CLEAR_SUBMITTED_EXPENSES';
export const clearSubmittedExpenses = () => dispatchWrapper(CLEAR_SUBMITTED_EXPENSES);

export const CLEAR_PAGED_USERS = 'CLEAR_PAGED_USERS';
export const clearPagedUsers = () => dispatchWrapper(CLEAR_PAGED_USERS);

export const CLEAR_USER_PROFILES_METADATA = 'CLEAR_USER_PROFILES_METADATA';
export const clearUserProfilesMetadata = () => dispatchWrapper(CLEAR_USER_PROFILES_METADATA);

export const CLEAR_YEARLY_REIMBURSEMENT_REPORT_ACTION = 'CLEAR_YEARLY_REIMBURSEMENT_REPORT';
export const clearYearlyReimbursementReport = () =>
    dispatchWrapper(CLEAR_YEARLY_REIMBURSEMENT_REPORT_ACTION);

export const CLEAR_REIMBURSEMENT_REPORT_ACTION = 'CLEAR_REIMBURSEMENT_REPORT';
export const clearReimbursementReport = () => dispatchWrapper(CLEAR_REIMBURSEMENT_REPORT_ACTION);

export const CLEAR_REIMBURSEMENT_REPORTS_ACTION = 'CLEAR_REIMBURSEMENT_REPORTS';
export const clearReimbursementReports = () => dispatchWrapper(CLEAR_REIMBURSEMENT_REPORTS_ACTION);

export const CLEAR_SELECTED_PLANS_ACTION = 'CLEAR_SELECTED_PLANS';
export const clearSelectedPlans = () => dispatchWrapper(CLEAR_SELECTED_PLANS_ACTION);

export const CLEAR_TEAM_BENEFIT_ACTION = 'CLEAR_TEAM_BENEFIT';
export const clearTeamBenefit = () => dispatchWrapper(CLEAR_TEAM_BENEFIT_ACTION);

export const CLEAR_USER_TERM_DETAILS_ACTION = 'CLEAR_USER_TERM_DETAILS_ACTION';
export const clearUserTermDetails = () => dispatchWrapper(CLEAR_USER_TERM_DETAILS_ACTION);

export const CLEAR_ANCILLARY_BENEFITS_ACTION = 'CLEAR_ANCILLARY_BENEFITS_ACTION';
export const clearAncillaryBenefits = () => dispatchWrapper(CLEAR_ANCILLARY_BENEFITS_ACTION);

export const CLEAR_COMPLETED_USER_TASKS_ACTION = 'CLEAR_COMPLETED_USER_TASKS_ACTION';
export const clearCompletedUserTasks = () => dispatchWrapper(CLEAR_COMPLETED_USER_TASKS_ACTION);

export const CLEAR_ENROLLMENT_DATA_ACTION = 'CLEAR_ENROLLMENT_DATA';
export const clearEnrollmentData = () => dispatchWrapper(CLEAR_ENROLLMENT_DATA_ACTION);

export const CLEAR_ACCOUNT_SNAPSHOT_ACTION = 'CLEAR_ACCOUNT_SNAPSHOT';
export const clearAccountSnapshot = () => dispatchWrapper(CLEAR_ACCOUNT_SNAPSHOT_ACTION);

export const CLEAR_IMPORTED_AGE_BANDED_TABLE_ACTION = 'CLEAR_IMPORTED_AGE_BANDED_TABLE';
export const clearImportedAgeBandedTable = () =>
    dispatchWrapper(CLEAR_IMPORTED_AGE_BANDED_TABLE_ACTION);

export const API_ACTIVITY_CLEAR_SHOPPING_PLAN_FETCHES = 'API_ACTIVITY_CLEAR_SHOPPING_PLAN_FETCHES';
export const clearApiActivityClearShoppingPlanFetches = () =>
    dispatchWrapper(API_ACTIVITY_CLEAR_SHOPPING_PLAN_FETCHES);
