import commonAction from 'actions/commonAction';
import { CopyTeamInfoRequest } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';

export const COPY_HOUSEHOLD_INFO_524_TOAST_MESSAGE =
    'The copy household data process is taking longer than expected. ' +
    'This could take up to 10 min for large teams. Please wait a bit ' +
    'to let the copy finish and check back later to see the updated data. ' +
    'You do not need to initiate this process again.';

export const COPY_HOUSEHOLD_INFO_ACTION = 'COPY_HOUSEHOLD_INFO';

export const copyHouseholdInfo = (
    teamId: string | undefined,
    sourceYear: number,
    targetYear: number | undefined,
    userId?: string
) => {
    const copyInfoRequest = new CopyTeamInfoRequest({
        sourceYear,
        targetYear: targetYear as number,
        teamId: teamId as string,
    });
    return commonAction(
        async () => new UsersApi().copyHouseholdInfo(userId, copyInfoRequest),
        COPY_HOUSEHOLD_INFO_ACTION,
        {
            toastErrorMessage(response) {
                if (response.error.message === 'Network Error') {
                    return COPY_HOUSEHOLD_INFO_524_TOAST_MESSAGE;
                } else {
                    return 'Unable to copy household data to year';
                }
            },
            toastSuccessMessage: `Household data copied from ${sourceYear} to ${targetYear} successfully`,
        }
    );
};
