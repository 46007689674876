import commonAction, { AppStoreThunkDispatch } from 'actions/commonAction';
import ShoppingApi from 'api/generated/ShoppingApi';

export const GET_IDEON_PROVIDER_PLANS_ACTION = 'GET_IDEON_PROVIDER_PLANS';

export const getIdeonProviderPlans = (npi: string, year: number) =>
    commonAction(
        async () => new ShoppingApi().getProviderPlans(npi, year),
        GET_IDEON_PROVIDER_PLANS_ACTION,
        {
            additionalDispatchData: {
                npi,
                params: {
                    npi,
                },
            },
            toastErrorMessage: 'Failed getting provider plans. Please try again',
        }
    );

export const clearIdeonProviderPlans = (npi?: string) => async (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({
        npi,
        type: GET_IDEON_PROVIDER_PLANS_ACTION.clear,
    });
};
