import commonAction from 'actions/commonAction';
import ProviderSearchApi from 'api/generated/ProviderSearchApi';
import { GetProvidersInPlanNetworkQuery } from 'api/generated/models';

export const GET_PROVIDERS_IN_PLAN_NETWORK_ACTION = 'GET_PROVIDERS_IN_PLAN_NETWORK';

export const getIdeonProvidersInPlanNetwork = (
    planId: string | undefined,
    planYear: number | undefined,
    providerIds: number[]
) =>
    commonAction(
        async () =>
            new ProviderSearchApi().getProvidersInPlanNetwork(
                new GetProvidersInPlanNetworkQuery({
                    providerIds,
                    planId: planId as string,
                    planYear: planYear as number,
                })
            ),
        GET_PROVIDERS_IN_PLAN_NETWORK_ACTION,
        {
            toastErrorMessage: 'Failed to get provider network information',
        }
    );
