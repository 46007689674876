import commonAction from 'actions/commonAction';
import UsersApi from 'api/generated/UsersApi';

export const PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION =
    'PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT';

export const processCoverageLevelForReimbursement = (
    householdMemberId: string,
    year: number,
    householdMemberNeedsCoverage: boolean,
    shouldClearSelectedPlansForReview: boolean
) =>
    commonAction(
        async () =>
            new UsersApi().processCoverageLevelForReimbursement(
                householdMemberId,
                year,
                householdMemberNeedsCoverage,
                shouldClearSelectedPlansForReview
            ),
        PROCESS_COVERAGE_LEVEL_FOR_REIMBURSEMENT_ACTION,
        {
            toastErrorMessage: 'Unable to update the household member',
            toastSuccessMessage: 'Successfully recalculated Reimbursement Amount',
        }
    );
