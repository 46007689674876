import commonAction from 'actions/commonAction';
import AdpApi from 'api/generated/AdpApi';

export const GET_ADP_USER_CONFIGURATIONS_ACTION = 'GET_ADP_USER_CONFIGURATIONS';

export const getAdpUserConfigurations = (teamId: string) =>
    commonAction(
        async () => new AdpApi().getAdpUserConfigurations(teamId),
        GET_ADP_USER_CONFIGURATIONS_ACTION
    );
