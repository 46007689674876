import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import IchraClassApi from 'api/generated/IchraClassApi';
import { IchraClass, IIchraClass } from 'api/generated/models';

export const ADD_ICHRA_CLASS_ACTION = 'ADD_ICHRA_CLASS';

export const addIchraClass = (ichraClass: IIchraClass) =>
    commonAction(
        async () => new IchraClassApi().addIchraClass(new IchraClass(ichraClass)),
        ADD_ICHRA_CLASS_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(ichraClass.pathwayBlueprintId));
            },
            successHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(ichraClass.pathwayBlueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to add ICHRA Class. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully added ICHRA Class',
        }
    );
