import commonAction from 'actions/commonAction';
import IchraClassApi from 'api/generated/IchraClassApi';
import { IIchraClass } from 'api/generated/models';

export const APPLY_STRATEGY_ACTION = 'APPLY_STRATEGY';

export const applyStrategy = (ichraClass: IIchraClass) =>
    commonAction(
        async () =>
            new IchraClassApi().applyStrategy(
                ichraClass.globalId,
                ichraClass.pathwayBlueprintId,
                ichraClass.topLineReimbursementStrategyId,
                ichraClass.benchmarkPlanType,
                ichraClass.zipCode,
                ichraClass.county,
                ichraClass.state,
                ichraClass.benchmarkCarrier,
                ichraClass.benchmarkPlanId,
                ichraClass.dependentKickerCoveragePercentage,
                Number(ichraClass.eeCoveragePercentage),
                ichraClass.esCoveragePercentage,
                ichraClass.ecCoveragePercentage,
                ichraClass.efCoveragePercentage,
                ichraClass.otherHouseholdMemberCoveragePercentage
            ),
        APPLY_STRATEGY_ACTION,
        {
            toastErrorMessage: (response) =>
                `Unable to apply strategy for ICHRA Class. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully applied strategy for ICHRA Class.',
        }
    );
